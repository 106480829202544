/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Karla_Fallback_0f9beb';src: local("Arial");ascent-override: 89.55%;descent-override: 24.61%;line-gap-override: 0.00%;size-adjust: 102.40%
}.__className_0f9beb {font-family: '__Karla_0f9beb', '__Karla_Fallback_0f9beb'
}.__variable_0f9beb {--font-karla: '__Karla_0f9beb', '__Karla_Fallback_0f9beb'
}

/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c60a942849f5899e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/261413652d32c1c3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/bc39dafafa9e6cfe-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/85933357a759c627-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/87cac5386ae78d2f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2bf82a7bb27b395e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/928f8fbd0ceb6185-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/23d8abc69d62f3d0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0c1e327e026a9f54-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/703d9a90a8aabcb9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/99c7ec355b5ddc3c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/b4561805fb5fcbf1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f1a801f7be0405c3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/2b9ff0c36ea32b8c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a4f00cf9cbfc4a42-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2bb25458ea2620e9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/092b00a2f3798ba2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f378bd2abf9e0d48-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1d769dbdf84e9e05-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/28fac4a6e903645b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6e5f11352df585dc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/969699447f100d3e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Titillium_Web_Fallback_ba33c6';src: local("Arial");ascent-override: 119.97%;descent-override: 41.09%;line-gap-override: 0.00%;size-adjust: 94.44%
}.__className_ba33c6 {font-family: '__Titillium_Web_ba33c6', '__Titillium_Web_Fallback_ba33c6'
}.__variable_ba33c6 {--font-titillium-web: '__Titillium_Web_ba33c6', '__Titillium_Web_Fallback_ba33c6'
}

@font-face {
font-family: '__komikax_8f8a8a';
src: url(/_next/static/media/f071c13dcb1912e6-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__komikax_Fallback_8f8a8a';src: local("Arial");ascent-override: 102.51%;descent-override: 18.94%;line-gap-override: 2.23%;size-adjust: 134.62%
}.__className_8f8a8a {font-family: '__komikax_8f8a8a', '__komikax_Fallback_8f8a8a'
}.__variable_8f8a8a {--font-komikax: '__komikax_8f8a8a', '__komikax_Fallback_8f8a8a'
}

